<template>
  <div>
    <b-overlay
      show
      spinner-variant="primary"
      spinner-type="grow"
      spinner-large
      rounded="sm"
      style="top: 25em;"
      v-if="overlayStatus"
    >
    </b-overlay>
    <div v-if="providers.length === 0 && overlayStatus === false">
      <Empty
        :no-button="false"
        icon="LoaderIcon"
        title="No hay datos para mostrar"
        content="Actualmente no hay proveedores, para ver las fotos de pesaje de cada proveedor, agrega uno primero."
        button-text="Crear un proveedor"
        :button-func="goToCreateProvider"
      />
    </div>
    <!-- Providers -->
    <b-tabs pills v-model="tabIndex">
      <b-tab v-for="provider in providers" :key="provider.idCompany">
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold"> {{ provider.companyName }} </span>
        </template>
        <b-card no-body>
          <!-- Header -->
          <b-card-header>
            <b-card-title> Fotos de pesajes </b-card-title>
            <b-card-text> Elige una fecha o un rango de fechas a mostrar </b-card-text>
          </b-card-header>
          <!-- Date Range -->
          <b-row align-h="center" class="mt-2 mb-4">
            <b-form-group
              label="Rango de fechas"
            >
              <flat-pickr
                class="form-control d-inline-block ml-50 mr-1"
                :config="{ dateFormat: 'd-m-Y', mode: 'range' }"
                v-model="dateRange"
              />
            </b-form-group>
            <b-button
              style="height: 38px; margin-top: 27px"
              class="ml-1"
              variant="primary"
              @click="getSnapshots(providers[tabIndex].idCompany, tabIndex)"
              :disabled="dateRange === ''"
            >
              <feather-icon
                icon="SearchIcon"
                size="16"
              />
            </b-button>
          </b-row>
          <hr>
          <b-card no-body class="fixed" style="padding: 1em">
            <b-row>
              <b-col lg="6" sm="12">
                <h5 class="ml-2 mt-2 mb-2" v-if="snapshots[tabIndex] && snapshots[tabIndex].snapshots">
                  Evidencias de pesajes ({{ snapshots[tabIndex].snapshots.length }} {{ snapshots[tabIndex].snapshots.length == 1 ? 'imagen' : 'imágenes' }}): 
                </h5>
              </b-col>
              <b-col lg="6" sm="12" class="d-flex align-items-end justify-content-end">
                <b-form-checkbox
                  class="ml-2 mt-2 mb-2 mr-2"
                  switch
                  v-model="showRealData"
                >
                  <h5>
                    Datos Reales
                  </h5>
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-card>
          <!-- Empty Snapshots -->
          <div v-if="snapshots[tabIndex] && snapshots[tabIndex].snapshots && snapshots[tabIndex].snapshots.length == 0" class="mt-4 mb-2" style="text-align: center">
            <h5>
              No hay registros.
            </h5>
            <b-row align-h="center">
              <b-col class="mt-1 mb-1 d-flex justify-content-center">
                <b-media no-body>
                  <b-media-aside style="margin-right: 0">
                    <b-avatar
                      size="60"
                      variant="light-info"
                    >
                      <feather-icon
                        size="24"
                        icon="CameraOffIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                </b-media>
              </b-col>
            </b-row>
          </div>
          <!-- Snapshots -->
          <b-overlay
            :show="tableOverlay"
            rounded="sm"
          >
            <div>
              <b-row v-if="snapshots[tabIndex] && snapshots[tabIndex].snapshots">
                <b-col
                  v-for="(snapshot, index) in snapshots[tabIndex].snapshots" :key="index"
                  lg="6"
                  sm="12"
                >
                  <center>
                    <div v-if="false && !showRealData && snapshot.weight == snapshot.alteredWeight" style="margin-bottom: -3px">
                      <b-avatar
                        variant="light-warning"
                      >
                        <feather-icon
                          size="24"
                          icon="AlertTriangleIcon"
                        />
                      </b-avatar>
                    </div>
                    <div v-else>
                      <h3>{{ formatNumber((showRealData) ? snapshot.weight : snapshot.alteredWeight) }} kg</h3>
                    </div>
                  </center>
                  <b-img-lazy 
                    :src="snapshot.url"
                    :alt="'Imagen #' + index"
                    class="mb-2 cursor-pointer img"
                    width="500em"
                    height="500em"
                    fluid
                    thumbnail
                    center
                  />
                </b-col>
              </b-row>
            </div>
          </b-overlay>
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>


<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.img:hover {
  transform: scale(1.15);
  transition: all .3s ease-in;
}

.fixed {
  position: sticky;
  top: 5.5em;
  z-index: 20;
  border-bottom: 3px solid #7367f0;
}
</style>


<script>
import { 
  BCard, BOverlay, BRow, BCol, BFormGroup, BButton, BAvatar, BTab, BTabs, BImg, BFormCheckbox,
  BCardHeader, BCardTitle, BCardText, BMediaAside, BMedia, BMediaBody, BImgLazy } from 'bootstrap-vue';

import utils from '@/utils/utils.js';
import Empty from '../components/empty-page/Empty';

import flatPickr from 'vue-flatpickr-component';
import { Spanish } from "flatpickr/dist/l10n/es.js";
flatpickr.localize(Spanish);

export default {
  components: {
    BCard,
    BCardText,
    BCardTitle,
    BCardHeader,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    flatPickr,
    BButton,
    BAvatar,
    BMediaAside,
    BMedia,
    BMediaBody,
    BTab,
    BTabs,
    BImg,
    Empty,
    BFormCheckbox,
    BImgLazy
  },
  data() {
    return {
      // Data
      snapshots: [],
      providers: [],

      // Filter
      dateRange: this.$moment().format('DD-MM-YYYY'),

      // Props
      overlayStatus: true,
      tableOverlay: true,
      tabIndex: 0,
      showRealData: true
    }
  },
  created() {
    this.getCompanies();
  },
  methods: {
    getCompanies() {
      const userData = JSON.parse(localStorage.getItem('userData'));
      this.$axios.get(this.$services + 'providers/get_providers').then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.providers = res.data.data;
          this.snapshots = new Array(this.providers.length).fill(null);
          if (this.$can('read', 'Calculator'))
            this.providers = this.providers.filter(provider => provider.idCompany == userData.idCompany);
          this.providers.forEach((provider, index) => {
            this.getSnapshots(provider.idCompany, index);
          });
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      }).finally(() => {
        this.overlayStatus = false;
      });
    },
    getSnapshots(idCompany, index) {

      this.tableOverlay = true;

      // Escoger una fecha o un rango de fechas
      let dates;
      this.dateRange.length == 10
      ? dates = [this.dateRange, this.dateRange]
      : dates = [this.dateRange.slice(0, 11), this.dateRange.slice(13, 25)];
      
      this.$axios.get(this.$services + 'security/get_snapshots', {
        params: {
          idCompany: idCompany,
          initialDate: parseInt(this.$moment(dates[0], 'DD-MM-YYYY').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format('x')),
          finalDate: parseInt(this.$moment(dates[1], 'DD-MM-YYYY').set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).format('x'))
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.snapshots.splice(index, 1, res.data.data);
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      }).finally(() => {
        this.tableOverlay = false;
      });
    },
    goToCreateProvider() {
      this.$router.push({ name: 'add-provider' })
    },
    formatNumber(value) {
      return utils.numberWithCommas(value);
    },
  }
}
</script>
